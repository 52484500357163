import axios from "axios";
import { UserApi } from "../generated-api";

const options = {
  isJsonMime: (mime: string) => mime === "application/json",
  headers: {
    "Content-Type": "application/json",
  },
};

const apiClientRaw = axios.create({
  // cdk によるビルド時は環境変数 API_BASE_URL が指定される。ない場合はローカル環境なのでローカルを見る
  baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:3003",
  withCredentials: true,
});

export const userApi = new UserApi(options, "", apiClientRaw);
