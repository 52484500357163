import { Spin } from "antd";

function Loading() {
  return (
    <div>
      <div
        style={{
          margin: "130px 0",
          textAlign: "center",
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );
}

export default Loading;
