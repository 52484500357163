/* tslint:disable */
/* eslint-disable */
/**
 * newtech-ma 顔認識
 * #prj-newtech-ma
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AdminAccount
 */
export interface AdminAccount {
  /**
   *
   * @type {number}
   * @memberof AdminAccount
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminAccount
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminAccount
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof AdminAccount
   */
  role: string;
}
/**
 *
 * @export
 * @interface AdminAccountCreateRequestDto
 */
export interface AdminAccountCreateRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminAccountCreateRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminAccountCreateRequestDto
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof AdminAccountCreateRequestDto
   */
  role: string;
}
/**
 *
 * @export
 * @interface AdminAccountCreateResponseDto
 */
export interface AdminAccountCreateResponseDto {
  /**
   *
   * @type {AdminAccount}
   * @memberof AdminAccountCreateResponseDto
   */
  account: AdminAccount;
}
/**
 *
 * @export
 * @interface AdminAccountListResponseDto
 */
export interface AdminAccountListResponseDto {
  /**
   *
   * @type {Array<AdminAccount>}
   * @memberof AdminAccountListResponseDto
   */
  accounts: Array<AdminAccount>;
}
/**
 *
 * @export
 * @interface AdminCompanyAccountBulkCreateRequestDto
 */
export interface AdminCompanyAccountBulkCreateRequestDto {
  /**
   *
   * @type {Array<NewCompanyAccount>}
   * @memberof AdminCompanyAccountBulkCreateRequestDto
   */
  accounts: Array<NewCompanyAccount>;
}
/**
 *
 * @export
 * @interface AdminCompanyAccountListResponseDto
 */
export interface AdminCompanyAccountListResponseDto {
  /**
   *
   * @type {Array<CompanyAccount>}
   * @memberof AdminCompanyAccountListResponseDto
   */
  accounts: Array<CompanyAccount>;
}
/**
 *
 * @export
 * @interface AdminCompanyCreateRequestDto
 */
export interface AdminCompanyCreateRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateRequestDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateRequestDto
   */
  representative_name: string;
  /**
   * 電話番号を表すハイフン無しの数字
   * @type {string}
   * @memberof AdminCompanyCreateRequestDto
   */
  telephone_number: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateRequestDto
   */
  contract_start_date: string;
}
/**
 *
 * @export
 * @interface AdminCompanyCreateResponseDto
 */
export interface AdminCompanyCreateResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminCompanyCreateResponseDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  representative_name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  telephone_number: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  contract_start_date: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCreateResponseDto
   */
  deleted_at: string | null;
}
/**
 *
 * @export
 * @interface AdminCompanyListResponseDto
 */
export interface AdminCompanyListResponseDto {
  /**
   *
   * @type {Array<Company>}
   * @memberof AdminCompanyListResponseDto
   */
  companies: Array<Company>;
}
/**
 *
 * @export
 * @interface AdminCompanyResponseDto
 */
export interface AdminCompanyResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminCompanyResponseDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  representative_name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  telephone_number: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  contract_start_date: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  deleted_at: string | null;
}
/**
 *
 * @export
 * @interface AdminCompanyUpdateRequestDto
 */
export interface AdminCompanyUpdateRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateRequestDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateRequestDto
   */
  representative_name: string;
  /**
   * 電話番号を表すハイフン無しの数字
   * @type {string}
   * @memberof AdminCompanyUpdateRequestDto
   */
  telephone_number: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateRequestDto
   */
  contract_start_date: string;
}
/**
 *
 * @export
 * @interface AdminCompanyUpdateResponseDto
 */
export interface AdminCompanyUpdateResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminCompanyUpdateResponseDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  representative_name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  telephone_number: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  contract_start_date: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyUpdateResponseDto
   */
  deleted_at: string | null;
}
/**
 *
 * @export
 * @interface AdminCompanyUsageMonthlyResponseDto
 */
export interface AdminCompanyUsageMonthlyResponseDto {
  /**
   *
   * @type {Array<MonthlyUsage>}
   * @memberof AdminCompanyUsageMonthlyResponseDto
   */
  monthly_usages: Array<MonthlyUsage>;
}
/**
 *
 * @export
 * @interface AdminCompanyUsageMonthlyYmResponseDto
 */
export interface AdminCompanyUsageMonthlyYmResponseDto {
  /**
   *
   * @type {Array<CompanyAccount>}
   * @memberof AdminCompanyUsageMonthlyYmResponseDto
   */
  accounts: Array<CompanyAccount>;
  /**
   *
   * @type {Array<IndividualMonthlyUsage>}
   * @memberof AdminCompanyUsageMonthlyYmResponseDto
   */
  individual_monthly_usages: Array<IndividualMonthlyUsage>;
}
/**
 *
 * @export
 * @interface AdminLoginRequestDto
 */
export interface AdminLoginRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminLoginRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminLoginRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface AdminLoginResponseDto
 */
export interface AdminLoginResponseDto {
  /**
   *
   * @type {AdminAccount}
   * @memberof AdminLoginResponseDto
   */
  account: AdminAccount;
}
/**
 *
 * @export
 * @interface AdminMeResponseDto
 */
export interface AdminMeResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminMeResponseDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminMeResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminMeResponseDto
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof AdminMeResponseDto
   */
  role: string;
}
/**
 *
 * @export
 * @interface AdminPasswordResetRequestDto
 */
export interface AdminPasswordResetRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminPasswordResetRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminPasswordResetRequestDto
   */
  password_reset_key: string;
  /**
   *
   * @type {string}
   * @memberof AdminPasswordResetRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface AdminPasswordResetRequestRequestDto
 */
export interface AdminPasswordResetRequestRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminPasswordResetRequestRequestDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {number}
   * @memberof Company
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  representative_name: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  telephone_number: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  contract_start_date: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  deleted_at: string | null;
}
/**
 *
 * @export
 * @interface CompanyAccount
 */
export interface CompanyAccount {
  /**
   *
   * @type {number}
   * @memberof CompanyAccount
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CompanyAccount
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof CompanyAccount
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CompanyAccount
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof CompanyAccount
   */
  role: string;
}
/**
 *
 * @export
 * @interface EmotionSummary
 */
export interface EmotionSummary {
  /**
   * 幸せ
   * @type {number}
   * @memberof EmotionSummary
   */
  happy: number;
  /**
   * 驚き
   * @type {number}
   * @memberof EmotionSummary
   */
  surprised: number;
  /**
   * 平常
   * @type {number}
   * @memberof EmotionSummary
   */
  neutral: number;
  /**
   * 嫌悪
   * @type {number}
   * @memberof EmotionSummary
   */
  disgusted: number;
  /**
   * 恐怖
   * @type {number}
   * @memberof EmotionSummary
   */
  fearful: number;
  /**
   * 悲しみ
   * @type {number}
   * @memberof EmotionSummary
   */
  sad: number;
  /**
   * 怒り
   * @type {number}
   * @memberof EmotionSummary
   */
  angry: number;
}
/**
 *
 * @export
 * @interface IndividualMonthlyUsage
 */
export interface IndividualMonthlyUsage {
  /**
   *
   * @type {number}
   * @memberof IndividualMonthlyUsage
   */
  company_id: number;
  /**
   *
   * @type {number}
   * @memberof IndividualMonthlyUsage
   */
  company_user_id: number;
  /**
   *
   * @type {number}
   * @memberof IndividualMonthlyUsage
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof IndividualMonthlyUsage
   */
  month: number;
  /**
   * 単位は秒
   * @type {number}
   * @memberof IndividualMonthlyUsage
   */
  monthly_usage: number;
}
/**
 *
 * @export
 * @interface Meeting
 */
export interface Meeting {
  /**
   *
   * @type {number}
   * @memberof Meeting
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Meeting
   */
  company_id: number;
  /**
   * 商談を登録したcompany_user_id
   * @type {number}
   * @memberof Meeting
   */
  company_user_id: number;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  started_at: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  finished_at: string;
  /**
   *
   * @type {EmotionSummary}
   * @memberof Meeting
   */
  internal_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof Meeting
   */
  external_summary: EmotionSummary;
  /**
   * 商談総合のサマリ。`1`から`3`の値を取る。1が低評価で、3が高評価。
   * @type {number}
   * @memberof Meeting
   */
  total_summary: number;
  /**
   *
   * @type {Array<MeetingAttendee>}
   * @memberof Meeting
   */
  internal_attendees: Array<MeetingAttendee>;
  /**
   *
   * @type {Array<MeetingAttendee>}
   * @memberof Meeting
   */
  external_attendees: Array<MeetingAttendee>;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  thumbnailUrl: string;
}
/**
 *
 * @export
 * @interface MeetingAttendee
 */
export interface MeetingAttendee {
  /**
   *
   * @type {number}
   * @memberof MeetingAttendee
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof MeetingAttendee
   */
  meeting_id: number;
  /**
   *
   * @type {string}
   * @memberof MeetingAttendee
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof MeetingAttendee
   */
  memo: string;
  /**
   *
   * @type {boolean}
   * @memberof MeetingAttendee
   */
  is_internal: boolean;
}
/**
 *
 * @export
 * @interface MeetingAttendeeScreenNameAndMemo
 */
export interface MeetingAttendeeScreenNameAndMemo {
  /**
   *
   * @type {string}
   * @memberof MeetingAttendeeScreenNameAndMemo
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof MeetingAttendeeScreenNameAndMemo
   */
  memo: string;
}
/**
 *
 * @export
 * @interface MonthlyUsage
 */
export interface MonthlyUsage {
  /**
   *
   * @type {number}
   * @memberof MonthlyUsage
   */
  company_id: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyUsage
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyUsage
   */
  month: number;
  /**
   * 単位は秒
   * @type {number}
   * @memberof MonthlyUsage
   */
  monthly_usage: number;
}
/**
 *
 * @export
 * @interface NewCompanyAccount
 */
export interface NewCompanyAccount {
  /**
   *
   * @type {string}
   * @memberof NewCompanyAccount
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof NewCompanyAccount
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof NewCompanyAccount
   */
  role: string;
}
/**
 *
 * @export
 * @interface SummaryDaily
 */
export interface SummaryDaily {
  /**
   * 「年」「月」「日」が有効な値。
   * @type {string}
   * @memberof SummaryDaily
   */
  date: string;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryDaily
   */
  internal_emotion_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryDaily
   */
  external_emotion_summary: EmotionSummary;
}
/**
 *
 * @export
 * @interface SummaryMonthly
 */
export interface SummaryMonthly {
  /**
   * 「年」「月」が有効な値。「日」は常に1
   * @type {string}
   * @memberof SummaryMonthly
   */
  date: string;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryMonthly
   */
  internal_emotion_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryMonthly
   */
  external_emotion_summary: EmotionSummary;
}
/**
 *
 * @export
 * @interface SummaryWeekly
 */
export interface SummaryWeekly {
  /**
   * 「年」「月」が有効な値。「日」は常に1
   * @type {string}
   * @memberof SummaryWeekly
   */
  date: string;
  /**
   * その月の第何週目かを表す
   * @type {number}
   * @memberof SummaryWeekly
   */
  week_of: number;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryWeekly
   */
  internal_emotion_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryWeekly
   */
  external_emotion_summary: EmotionSummary;
}
/**
 *
 * @export
 * @interface SummaryYear
 */
export interface SummaryYear {
  /**
   * 「年」が有効な値。「月」「日」は常に1
   * @type {string}
   * @memberof SummaryYear
   */
  date: string;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryYear
   */
  internal_emotion_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof SummaryYear
   */
  external_emotion_summary: EmotionSummary;
}
/**
 *
 * @export
 * @interface UserAccountListResponseDto
 */
export interface UserAccountListResponseDto {
  /**
   *
   * @type {Array<CompanyAccount>}
   * @memberof UserAccountListResponseDto
   */
  accounts: Array<CompanyAccount>;
}
/**
 *
 * @export
 * @interface UserLoginRequestDto
 */
export interface UserLoginRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserLoginRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserLoginRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface UserLoginResponseDto
 */
export interface UserLoginResponseDto {
  /**
   *
   * @type {CompanyAccount}
   * @memberof UserLoginResponseDto
   */
  account: CompanyAccount;
}
/**
 *
 * @export
 * @interface UserMeResponseDto
 */
export interface UserMeResponseDto {
  /**
   *
   * @type {number}
   * @memberof UserMeResponseDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UserMeResponseDto
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof UserMeResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserMeResponseDto
   */
  screen_name: string;
  /**
   *
   * @type {string}
   * @memberof UserMeResponseDto
   */
  role: string;
}
/**
 *
 * @export
 * @interface UserMeetingListResponseDto
 */
export interface UserMeetingListResponseDto {
  /**
   *
   * @type {Array<Meeting>}
   * @memberof UserMeetingListResponseDto
   */
  meetings: Array<Meeting>;
}
/**
 *
 * @export
 * @interface UserMeetingResponseDto
 */
export interface UserMeetingResponseDto {
  /**
   *
   * @type {number}
   * @memberof UserMeetingResponseDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UserMeetingResponseDto
   */
  company_id: number;
  /**
   * 商談を登録したcompany_user_id
   * @type {number}
   * @memberof UserMeetingResponseDto
   */
  company_user_id: number;
  /**
   *
   * @type {string}
   * @memberof UserMeetingResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserMeetingResponseDto
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof UserMeetingResponseDto
   */
  started_at: string;
  /**
   *
   * @type {string}
   * @memberof UserMeetingResponseDto
   */
  finished_at: string;
  /**
   *
   * @type {EmotionSummary}
   * @memberof UserMeetingResponseDto
   */
  internal_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof UserMeetingResponseDto
   */
  external_summary: EmotionSummary;
  /**
   * 商談総合のサマリ。`1`から`3`の値を取る。1が低評価で、3が高評価。
   * @type {number}
   * @memberof UserMeetingResponseDto
   */
  total_summary: number;
  /**
   *
   * @type {Array<MeetingAttendee>}
   * @memberof UserMeetingResponseDto
   */
  internal_attendees: Array<MeetingAttendee>;
  /**
   *
   * @type {Array<MeetingAttendee>}
   * @memberof UserMeetingResponseDto
   */
  external_attendees: Array<MeetingAttendee>;
  /**
   *
   * @type {string}
   * @memberof UserMeetingResponseDto
   */
  thumbnailUrl: string;
}
/**
 *
 * @export
 * @interface UserMeetingUploadRequestDto
 */
export interface UserMeetingUploadRequestDto {
  /**
   *
   * @type {number}
   * @memberof UserMeetingUploadRequestDto
   */
  company_id: number;
  /**
   * 商談を登録したcompany_user_id
   * @type {number}
   * @memberof UserMeetingUploadRequestDto
   */
  company_user_id: number;
  /**
   *
   * @type {string}
   * @memberof UserMeetingUploadRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserMeetingUploadRequestDto
   */
  started_at: string;
  /**
   *
   * @type {string}
   * @memberof UserMeetingUploadRequestDto
   */
  finished_at: string;
  /**
   * 実際にfaceapi.jsで解析した長さ(分)。秒以下は切り上げる。ex: 2分1秒 → 3分
   * @type {number}
   * @memberof UserMeetingUploadRequestDto
   */
  length_minute: number;
  /**
   * 会議を行った日付。started_at と finished_at が日をまたいだ場合は started_at の日付にする
   * @type {string}
   * @memberof UserMeetingUploadRequestDto
   */
  date: string;
  /**
   *
   * @type {EmotionSummary}
   * @memberof UserMeetingUploadRequestDto
   */
  internal_summary: EmotionSummary;
  /**
   *
   * @type {EmotionSummary}
   * @memberof UserMeetingUploadRequestDto
   */
  external_summary: EmotionSummary;
  /**
   *
   * @type {number}
   * @memberof UserMeetingUploadRequestDto
   */
  total_summary: number;
  /**
   *
   * @type {Array<MeetingAttendeeScreenNameAndMemo>}
   * @memberof UserMeetingUploadRequestDto
   */
  internal_attendees: Array<MeetingAttendeeScreenNameAndMemo>;
  /**
   *
   * @type {Array<MeetingAttendeeScreenNameAndMemo>}
   * @memberof UserMeetingUploadRequestDto
   */
  external_attendees: Array<MeetingAttendeeScreenNameAndMemo>;
}
/**
 *
 * @export
 * @interface UserMeetingUploadResponseDto
 */
export interface UserMeetingUploadResponseDto {
  /**
   * csvをアップロードするpresigned url
   * @type {string}
   * @memberof UserMeetingUploadResponseDto
   */
  presigned_url_csv: string;
  /**
   * 折れ線グラフ用csvをアップロードするpresigned url
   * @type {string}
   * @memberof UserMeetingUploadResponseDto
   */
  presigned_url_line_graph_csv: string;
  /**
   * サムネイルの画像をアップロードするpresigned url
   * @type {string}
   * @memberof UserMeetingUploadResponseDto
   */
  presigned_url_thumbnail: string;
}
/**
 *
 * @export
 * @interface UserPasswordResetRequestDto
 */
export interface UserPasswordResetRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserPasswordResetRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserPasswordResetRequestDto
   */
  password_reset_key: string;
  /**
   *
   * @type {string}
   * @memberof UserPasswordResetRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface UserPasswordResetRequestRequestDto
 */
export interface UserPasswordResetRequestRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserPasswordResetRequestRequestDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface UserSummaryResponseDto
 */
export interface UserSummaryResponseDto {
  /**
   *
   * @type {SummaryYear}
   * @memberof UserSummaryResponseDto
   */
  summary_year: SummaryYear;
  /**
   *
   * @type {Array<SummaryMonthly>}
   * @memberof UserSummaryResponseDto
   */
  summaries_monthly: Array<SummaryMonthly>;
  /**
   *
   * @type {Array<SummaryWeekly>}
   * @memberof UserSummaryResponseDto
   */
  summaries_weekly: Array<SummaryWeekly>;
  /**
   *
   * @type {Array<SummaryDaily>}
   * @memberof UserSummaryResponseDto
   */
  summaries_daily: Array<SummaryDaily>;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {AdminAccountCreateRequestDto} adminAccountCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAccountCreate: async (
      adminAccountCreateRequestDto: AdminAccountCreateRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminAccountCreateRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerAccountCreate",
        "adminAccountCreateRequestDto",
        adminAccountCreateRequestDto
      );
      const localVarPath = `/admin/account/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminAccountCreateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAccountDelete: async (
      accountId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("adminControllerAccountDelete", "accountId", accountId);
      const localVarPath = `/admin/account/{account_id}/delete`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAccountList: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/account/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompany: async (
      companyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists("adminControllerCompany", "companyId", companyId);
      const localVarPath = `/admin/company/{company_id}`.replace(
        `{${"company_id"}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {AdminCompanyAccountBulkCreateRequestDto} adminCompanyAccountBulkCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyAccountBulkCreate: async (
      companyId: number,
      adminCompanyAccountBulkCreateRequestDto: AdminCompanyAccountBulkCreateRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyAccountBulkCreate",
        "companyId",
        companyId
      );
      // verify required parameter 'adminCompanyAccountBulkCreateRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerCompanyAccountBulkCreate",
        "adminCompanyAccountBulkCreateRequestDto",
        adminCompanyAccountBulkCreateRequestDto
      );
      const localVarPath =
        `/admin/company/{company_id}/account/bulk_create`.replace(
          `{${"company_id"}}`,
          encodeURIComponent(String(companyId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCompanyAccountBulkCreateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyAccountDelete: async (
      companyId: number,
      accountId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyAccountDelete",
        "companyId",
        companyId
      );
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyAccountDelete",
        "accountId",
        accountId
      );
      const localVarPath =
        `/admin/company/{company_id}/account/{account_id}/delete`
          .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
          .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyAccountList: async (
      companyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyAccountList",
        "companyId",
        companyId
      );
      const localVarPath = `/admin/company/{company_id}/account/list`.replace(
        `{${"company_id"}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminCompanyCreateRequestDto} adminCompanyCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyCreate: async (
      adminCompanyCreateRequestDto: AdminCompanyCreateRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminCompanyCreateRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerCompanyCreate",
        "adminCompanyCreateRequestDto",
        adminCompanyCreateRequestDto
      );
      const localVarPath = `/admin/company/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCompanyCreateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyDelete: async (
      companyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists("adminControllerCompanyDelete", "companyId", companyId);
      const localVarPath = `/admin/company/{company_id}/delete`.replace(
        `{${"company_id"}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 企業一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyList: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/company/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyPhysicDelete: async (
      companyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyPhysicDelete",
        "companyId",
        companyId
      );
      const localVarPath = `/admin/company/{company_id}/physic_delete`.replace(
        `{${"company_id"}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {AdminCompanyUpdateRequestDto} adminCompanyUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyUpdate: async (
      companyId: number,
      adminCompanyUpdateRequestDto: AdminCompanyUpdateRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists("adminControllerCompanyUpdate", "companyId", companyId);
      // verify required parameter 'adminCompanyUpdateRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerCompanyUpdate",
        "adminCompanyUpdateRequestDto",
        adminCompanyUpdateRequestDto
      );
      const localVarPath = `/admin/company/{company_id}/update`.replace(
        `{${"company_id"}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCompanyUpdateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyUsageMonthly: async (
      companyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyUsageMonthly",
        "companyId",
        companyId
      );
      const localVarPath = `/admin/company/{company_id}/usage_monthly`.replace(
        `{${"company_id"}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} companyId
     * @param {number} year
     * @param {number} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyUsageMonthlyYm: async (
      companyId: number,
      year: number,
      month: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        "adminControllerCompanyUsageMonthlyYm",
        "companyId",
        companyId
      );
      // verify required parameter 'year' is not null or undefined
      assertParamExists("adminControllerCompanyUsageMonthlyYm", "year", year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists("adminControllerCompanyUsageMonthlyYm", "month", month);
      const localVarPath =
        `/admin/company/{company_id}/usage_monthly/{year}/{month}`
          .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
          .replace(`{${"year"}}`, encodeURIComponent(String(year)))
          .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminLoginRequestDto} adminLoginRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerLogin: async (
      adminLoginRequestDto: AdminLoginRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminLoginRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerLogin",
        "adminLoginRequestDto",
        adminLoginRequestDto
      );
      const localVarPath = `/admin/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminLoginRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerLogout: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("adminControllerLogout", "body", body);
      const localVarPath = `/admin/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ログイン中のアカウント情報を返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerMe: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminPasswordResetRequestDto} adminPasswordResetRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerPasswordReset: async (
      adminPasswordResetRequestDto: AdminPasswordResetRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminPasswordResetRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerPasswordReset",
        "adminPasswordResetRequestDto",
        adminPasswordResetRequestDto
      );
      const localVarPath = `/admin/password_reset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminPasswordResetRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminPasswordResetRequestRequestDto} adminPasswordResetRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerPasswordResetRequest: async (
      adminPasswordResetRequestRequestDto: AdminPasswordResetRequestRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminPasswordResetRequestRequestDto' is not null or undefined
      assertParamExists(
        "adminControllerPasswordResetRequest",
        "adminPasswordResetRequestRequestDto",
        adminPasswordResetRequestRequestDto
      );
      const localVarPath = `/admin/password_reset_request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminPasswordResetRequestRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AdminAccountCreateRequestDto} adminAccountCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerAccountCreate(
      adminAccountCreateRequestDto: AdminAccountCreateRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminAccountCreateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerAccountCreate(
          adminAccountCreateRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerAccountDelete(
      accountId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerAccountDelete(
          accountId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerAccountList(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminAccountListResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerAccountList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompany(
      companyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompany(
          companyId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {AdminCompanyAccountBulkCreateRequestDto} adminCompanyAccountBulkCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyAccountBulkCreate(
      companyId: number,
      adminCompanyAccountBulkCreateRequestDto: AdminCompanyAccountBulkCreateRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyAccountBulkCreate(
          companyId,
          adminCompanyAccountBulkCreateRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyAccountDelete(
      companyId: number,
      accountId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyAccountDelete(
          companyId,
          accountId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyAccountList(
      companyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyAccountListResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyAccountList(
          companyId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {AdminCompanyCreateRequestDto} adminCompanyCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyCreate(
      adminCompanyCreateRequestDto: AdminCompanyCreateRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyCreateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyCreate(
          adminCompanyCreateRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyDelete(
      companyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyDelete(
          companyId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary 企業一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyList(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyListResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyPhysicDelete(
      companyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyPhysicDelete(
          companyId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {AdminCompanyUpdateRequestDto} adminCompanyUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyUpdate(
      companyId: number,
      adminCompanyUpdateRequestDto: AdminCompanyUpdateRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyUpdateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyUpdate(
          companyId,
          adminCompanyUpdateRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyUsageMonthly(
      companyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyUsageMonthlyResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyUsageMonthly(
          companyId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} companyId
     * @param {number} year
     * @param {number} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCompanyUsageMonthlyYm(
      companyId: number,
      year: number,
      month: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminCompanyUsageMonthlyYmResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCompanyUsageMonthlyYm(
          companyId,
          year,
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {AdminLoginRequestDto} adminLoginRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerLogin(
      adminLoginRequestDto: AdminLoginRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminLoginResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerLogin(
          adminLoginRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerLogout(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerLogout(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary ログイン中のアカウント情報を返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerMe(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminMeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerMe(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {AdminPasswordResetRequestDto} adminPasswordResetRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerPasswordReset(
      adminPasswordResetRequestDto: AdminPasswordResetRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerPasswordReset(
          adminPasswordResetRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {AdminPasswordResetRequestRequestDto} adminPasswordResetRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerPasswordResetRequest(
      adminPasswordResetRequestRequestDto: AdminPasswordResetRequestRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerPasswordResetRequest(
          adminPasswordResetRequestRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminApiFp(configuration);
  return {
    /**
     *
     * @param {AdminAccountCreateRequestDto} adminAccountCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAccountCreate(
      adminAccountCreateRequestDto: AdminAccountCreateRequestDto,
      options?: any
    ): AxiosPromise<AdminAccountCreateResponseDto> {
      return localVarFp
        .adminControllerAccountCreate(adminAccountCreateRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAccountDelete(
      accountId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerAccountDelete(accountId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAccountList(
      options?: any
    ): AxiosPromise<AdminAccountListResponseDto> {
      return localVarFp
        .adminControllerAccountList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompany(
      companyId: number,
      options?: any
    ): AxiosPromise<AdminCompanyResponseDto> {
      return localVarFp
        .adminControllerCompany(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {AdminCompanyAccountBulkCreateRequestDto} adminCompanyAccountBulkCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyAccountBulkCreate(
      companyId: number,
      adminCompanyAccountBulkCreateRequestDto: AdminCompanyAccountBulkCreateRequestDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerCompanyAccountBulkCreate(
          companyId,
          adminCompanyAccountBulkCreateRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyAccountDelete(
      companyId: number,
      accountId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerCompanyAccountDelete(companyId, accountId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyAccountList(
      companyId: number,
      options?: any
    ): AxiosPromise<AdminCompanyAccountListResponseDto> {
      return localVarFp
        .adminControllerCompanyAccountList(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminCompanyCreateRequestDto} adminCompanyCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyCreate(
      adminCompanyCreateRequestDto: AdminCompanyCreateRequestDto,
      options?: any
    ): AxiosPromise<AdminCompanyCreateResponseDto> {
      return localVarFp
        .adminControllerCompanyCreate(adminCompanyCreateRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyDelete(
      companyId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerCompanyDelete(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 企業一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyList(
      options?: any
    ): AxiosPromise<AdminCompanyListResponseDto> {
      return localVarFp
        .adminControllerCompanyList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyPhysicDelete(
      companyId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerCompanyPhysicDelete(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {AdminCompanyUpdateRequestDto} adminCompanyUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyUpdate(
      companyId: number,
      adminCompanyUpdateRequestDto: AdminCompanyUpdateRequestDto,
      options?: any
    ): AxiosPromise<AdminCompanyUpdateResponseDto> {
      return localVarFp
        .adminControllerCompanyUpdate(
          companyId,
          adminCompanyUpdateRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyUsageMonthly(
      companyId: number,
      options?: any
    ): AxiosPromise<AdminCompanyUsageMonthlyResponseDto> {
      return localVarFp
        .adminControllerCompanyUsageMonthly(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} companyId
     * @param {number} year
     * @param {number} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCompanyUsageMonthlyYm(
      companyId: number,
      year: number,
      month: number,
      options?: any
    ): AxiosPromise<AdminCompanyUsageMonthlyYmResponseDto> {
      return localVarFp
        .adminControllerCompanyUsageMonthlyYm(companyId, year, month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminLoginRequestDto} adminLoginRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerLogin(
      adminLoginRequestDto: AdminLoginRequestDto,
      options?: any
    ): AxiosPromise<AdminLoginResponseDto> {
      return localVarFp
        .adminControllerLogin(adminLoginRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerLogout(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .adminControllerLogout(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ログイン中のアカウント情報を返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerMe(options?: any): AxiosPromise<AdminMeResponseDto> {
      return localVarFp
        .adminControllerMe(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminPasswordResetRequestDto} adminPasswordResetRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerPasswordReset(
      adminPasswordResetRequestDto: AdminPasswordResetRequestDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerPasswordReset(adminPasswordResetRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminPasswordResetRequestRequestDto} adminPasswordResetRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerPasswordResetRequest(
      adminPasswordResetRequestRequestDto: AdminPasswordResetRequestRequestDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .adminControllerPasswordResetRequest(
          adminPasswordResetRequestRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @param {AdminAccountCreateRequestDto} adminAccountCreateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerAccountCreate(
    adminAccountCreateRequestDto: AdminAccountCreateRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerAccountCreate(adminAccountCreateRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerAccountDelete(
    accountId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerAccountDelete(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerAccountList(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminControllerAccountList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompany(
    companyId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompany(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {AdminCompanyAccountBulkCreateRequestDto} adminCompanyAccountBulkCreateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyAccountBulkCreate(
    companyId: number,
    adminCompanyAccountBulkCreateRequestDto: AdminCompanyAccountBulkCreateRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyAccountBulkCreate(
        companyId,
        adminCompanyAccountBulkCreateRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyAccountDelete(
    companyId: number,
    accountId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyAccountDelete(companyId, accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyAccountList(
    companyId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyAccountList(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminCompanyCreateRequestDto} adminCompanyCreateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyCreate(
    adminCompanyCreateRequestDto: AdminCompanyCreateRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyCreate(adminCompanyCreateRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyDelete(
    companyId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyDelete(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 企業一覧を取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyList(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyPhysicDelete(
    companyId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyPhysicDelete(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {AdminCompanyUpdateRequestDto} adminCompanyUpdateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyUpdate(
    companyId: number,
    adminCompanyUpdateRequestDto: AdminCompanyUpdateRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyUpdate(
        companyId,
        adminCompanyUpdateRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyUsageMonthly(
    companyId: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyUsageMonthly(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} companyId
   * @param {number} year
   * @param {number} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerCompanyUsageMonthlyYm(
    companyId: number,
    year: number,
    month: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerCompanyUsageMonthlyYm(companyId, year, month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminLoginRequestDto} adminLoginRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerLogin(
    adminLoginRequestDto: AdminLoginRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerLogin(adminLoginRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerLogout(body: object, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminControllerLogout(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ログイン中のアカウント情報を返す
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerMe(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminControllerMe(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminPasswordResetRequestDto} adminPasswordResetRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerPasswordReset(
    adminPasswordResetRequestDto: AdminPasswordResetRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerPasswordReset(adminPasswordResetRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminPasswordResetRequestRequestDto} adminPasswordResetRequestRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerPasswordResetRequest(
    adminPasswordResetRequestRequestDto: AdminPasswordResetRequestRequestDto,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminControllerPasswordResetRequest(
        adminPasswordResetRequestRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHi: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/hi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetHello(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerGetHello(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetHi(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerGetHi(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello(options?: any): AxiosPromise<void> {
      return localVarFp
        .appControllerGetHello(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHi(options?: any): AxiosPromise<void> {
      return localVarFp
        .appControllerGetHi(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetHello(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appControllerGetHello(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetHi(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appControllerGetHi(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerAccountList: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/account/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserLoginRequestDto} userLoginRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerLogin: async (
      userLoginRequestDto: UserLoginRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userLoginRequestDto' is not null or undefined
      assertParamExists(
        "userControllerLogin",
        "userLoginRequestDto",
        userLoginRequestDto
      );
      const localVarPath = `/user/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userLoginRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerLogout: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("userControllerLogout", "body", body);
      const localVarPath = `/user/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMe: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeeting: async (
      meetingId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'meetingId' is not null or undefined
      assertParamExists("userControllerMeeting", "meetingId", meetingId);
      const localVarPath = `/user/meeting/{meeting_id}`.replace(
        `{${"meeting_id"}}`,
        encodeURIComponent(String(meetingId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingCsv: async (
      meetingId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'meetingId' is not null or undefined
      assertParamExists("userControllerMeetingCsv", "meetingId", meetingId);
      const localVarPath = `/user/meeting/{meeting_id}/csv`.replace(
        `{${"meeting_id"}}`,
        encodeURIComponent(String(meetingId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingLineGraph: async (
      meetingId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'meetingId' is not null or undefined
      assertParamExists(
        "userControllerMeetingLineGraph",
        "meetingId",
        meetingId
      );
      const localVarPath = `/user/meeting/{meeting_id}/line-graph`.replace(
        `{${"meeting_id"}}`,
        encodeURIComponent(String(meetingId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingList: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/meeting/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingThumbnail: async (
      meetingId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'meetingId' is not null or undefined
      assertParamExists(
        "userControllerMeetingThumbnail",
        "meetingId",
        meetingId
      );
      const localVarPath = `/user/meeting/{meeting_id}/thumbnail`.replace(
        `{${"meeting_id"}}`,
        encodeURIComponent(String(meetingId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary グラフ描画に用いるCSV, サムネイルはpresigned urlでアップロードする
     * @param {UserMeetingUploadRequestDto} userMeetingUploadRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingUpload: async (
      userMeetingUploadRequestDto: UserMeetingUploadRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userMeetingUploadRequestDto' is not null or undefined
      assertParamExists(
        "userControllerMeetingUpload",
        "userMeetingUploadRequestDto",
        userMeetingUploadRequestDto
      );
      const localVarPath = `/user/meeting/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userMeetingUploadRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserPasswordResetRequestDto} userPasswordResetRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerPasswordReset: async (
      userPasswordResetRequestDto: UserPasswordResetRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userPasswordResetRequestDto' is not null or undefined
      assertParamExists(
        "userControllerPasswordReset",
        "userPasswordResetRequestDto",
        userPasswordResetRequestDto
      );
      const localVarPath = `/user/password_reset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPasswordResetRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserPasswordResetRequestRequestDto} userPasswordResetRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerPasswordResetRequest: async (
      userPasswordResetRequestRequestDto: UserPasswordResetRequestRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userPasswordResetRequestRequestDto' is not null or undefined
      assertParamExists(
        "userControllerPasswordResetRequest",
        "userPasswordResetRequestRequestDto",
        userPasswordResetRequestRequestDto
      );
      const localVarPath = `/user/password_reset_request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPasswordResetRequestRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} year
     * @param {string} month
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerSummary: async (
      year: string,
      month: string,
      date: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists("userControllerSummary", "year", year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists("userControllerSummary", "month", month);
      // verify required parameter 'date' is not null or undefined
      assertParamExists("userControllerSummary", "date", date);
      const localVarPath = `/user/summary/{year}/{month}/{date}`
        .replace(`{${"year"}}`, encodeURIComponent(String(year)))
        .replace(`{${"month"}}`, encodeURIComponent(String(month)))
        .replace(`{${"date"}}`, encodeURIComponent(String(date)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerAccountList(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserAccountListResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerAccountList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UserLoginRequestDto} userLoginRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerLogin(
      userLoginRequestDto: UserLoginRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserLoginResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerLogin(
          userLoginRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerLogout(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerLogout(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMe(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserMeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMe(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMeeting(
      meetingId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserMeetingResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMeeting(
          meetingId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMeetingCsv(
      meetingId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMeetingCsv(
          meetingId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMeetingLineGraph(
      meetingId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMeetingLineGraph(
          meetingId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMeetingList(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserMeetingListResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMeetingList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMeetingThumbnail(
      meetingId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMeetingThumbnail(
          meetingId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary グラフ描画に用いるCSV, サムネイルはpresigned urlでアップロードする
     * @param {UserMeetingUploadRequestDto} userMeetingUploadRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerMeetingUpload(
      userMeetingUploadRequestDto: UserMeetingUploadRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserMeetingUploadResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerMeetingUpload(
          userMeetingUploadRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UserPasswordResetRequestDto} userPasswordResetRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerPasswordReset(
      userPasswordResetRequestDto: UserPasswordResetRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerPasswordReset(
          userPasswordResetRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UserPasswordResetRequestRequestDto} userPasswordResetRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerPasswordResetRequest(
      userPasswordResetRequestRequestDto: UserPasswordResetRequestRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerPasswordResetRequest(
          userPasswordResetRequestRequestDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} year
     * @param {string} month
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerSummary(
      year: string,
      month: string,
      date: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserSummaryResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userControllerSummary(
          year,
          month,
          date,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerAccountList(
      options?: any
    ): AxiosPromise<UserAccountListResponseDto> {
      return localVarFp
        .userControllerAccountList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserLoginRequestDto} userLoginRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerLogin(
      userLoginRequestDto: UserLoginRequestDto,
      options?: any
    ): AxiosPromise<UserLoginResponseDto> {
      return localVarFp
        .userControllerLogin(userLoginRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerLogout(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .userControllerLogout(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMe(options?: any): AxiosPromise<UserMeResponseDto> {
      return localVarFp
        .userControllerMe(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeeting(
      meetingId: number,
      options?: any
    ): AxiosPromise<UserMeetingResponseDto> {
      return localVarFp
        .userControllerMeeting(meetingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingCsv(
      meetingId: number,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .userControllerMeetingCsv(meetingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingLineGraph(
      meetingId: number,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .userControllerMeetingLineGraph(meetingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingList(
      options?: any
    ): AxiosPromise<UserMeetingListResponseDto> {
      return localVarFp
        .userControllerMeetingList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} meetingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingThumbnail(
      meetingId: number,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .userControllerMeetingThumbnail(meetingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary グラフ描画に用いるCSV, サムネイルはpresigned urlでアップロードする
     * @param {UserMeetingUploadRequestDto} userMeetingUploadRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMeetingUpload(
      userMeetingUploadRequestDto: UserMeetingUploadRequestDto,
      options?: any
    ): AxiosPromise<UserMeetingUploadResponseDto> {
      return localVarFp
        .userControllerMeetingUpload(userMeetingUploadRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserPasswordResetRequestDto} userPasswordResetRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerPasswordReset(
      userPasswordResetRequestDto: UserPasswordResetRequestDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .userControllerPasswordReset(userPasswordResetRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserPasswordResetRequestRequestDto} userPasswordResetRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerPasswordResetRequest(
      userPasswordResetRequestRequestDto: UserPasswordResetRequestRequestDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .userControllerPasswordResetRequest(
          userPasswordResetRequestRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} year
     * @param {string} month
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerSummary(
      year: string,
      month: string,
      date: string,
      options?: any
    ): AxiosPromise<UserSummaryResponseDto> {
      return localVarFp
        .userControllerSummary(year, month, date, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerAccountList(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerAccountList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserLoginRequestDto} userLoginRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerLogin(
    userLoginRequestDto: UserLoginRequestDto,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerLogin(userLoginRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerLogout(body: object, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerLogout(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMe(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerMe(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} meetingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMeeting(
    meetingId: number,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerMeeting(meetingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} meetingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMeetingCsv(
    meetingId: number,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerMeetingCsv(meetingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} meetingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMeetingLineGraph(
    meetingId: number,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerMeetingLineGraph(meetingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMeetingList(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerMeetingList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} meetingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMeetingThumbnail(
    meetingId: number,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerMeetingThumbnail(meetingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary グラフ描画に用いるCSV, サムネイルはpresigned urlでアップロードする
   * @param {UserMeetingUploadRequestDto} userMeetingUploadRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerMeetingUpload(
    userMeetingUploadRequestDto: UserMeetingUploadRequestDto,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerMeetingUpload(userMeetingUploadRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPasswordResetRequestDto} userPasswordResetRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerPasswordReset(
    userPasswordResetRequestDto: UserPasswordResetRequestDto,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerPasswordReset(userPasswordResetRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPasswordResetRequestRequestDto} userPasswordResetRequestRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerPasswordResetRequest(
    userPasswordResetRequestRequestDto: UserPasswordResetRequestRequestDto,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerPasswordResetRequest(
        userPasswordResetRequestRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} year
   * @param {string} month
   * @param {string} date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerSummary(
    year: string,
    month: string,
    date: string,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userControllerSummary(year, month, date, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
