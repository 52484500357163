import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { meState, useFetchMe } from "./useMeStore";

export const isAuthorizedState = atom<boolean>({
  key: "AuthStore/isAuthorized",
  default: false,
});

const isAuthInitiatedState = atom<boolean>({
  key: "AuthStore/isAuthInitiatedState",
  default: false,
});

export const useAuthStore = (): [boolean, boolean] => {
  const isAuthInitiated = useRecoilValue(isAuthInitiatedState);
  const isAuthorized = useRecoilValue(isAuthorizedState);
  return [isAuthInitiated, isAuthorized];
};

export const useInitAuth = (): (() => Promise<void>) => {
  const [isAuthInitiated, setIsAuthInitiated] =
    useRecoilState(isAuthInitiatedState);
  const setIsAuthorized = useSetRecoilState(isAuthorizedState);
  const fetchMe = useFetchMe();

  return useCallback(async () => {
    if (!isAuthInitiated) {
      try {
        await fetchMe();
        setIsAuthorized(true);
        setIsAuthInitiated(true);
      } catch {
        setIsAuthInitiated(true);
      }
    }
  }, [fetchMe, isAuthInitiated, setIsAuthInitiated, setIsAuthorized]);
};

export const useCommitLogin = (): (() => void) => {
  const setIsAuthorized = useSetRecoilState(isAuthorizedState);

  return useCallback(() => {
    setIsAuthorized(true);
  }, [setIsAuthorized]);
};

export const useCommitLogout = (): (() => void) => {
  const setIsAuthorized = useSetRecoilState(isAuthorizedState);
  const setMe = useSetRecoilState(meState);
  const navigate = useNavigate();
  return useCallback(() => {
    setIsAuthorized(false);
    setMe(null);
    navigate("/login");
  }, [navigate, setIsAuthorized, setMe]);
};
