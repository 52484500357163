import "./App.less";
import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import Loading from "./pages/info/LoadingPage";
import {
  isAuthorizedState,
  useAuthStore,
  useInitAuth,
} from "./store/useAuthStore";

const LoginPage = React.lazy(() => import("./pages/login/LoginPage"));
const PasswordResetPage = React.lazy(
  () => import("./pages/password/PasswordReset")
);
const PasswordChangePage = React.lazy(
  () => import("./pages/password/PasswordChange")
);
const TopPage = React.lazy(() => import("./pages/top/TopPage"));
const ResultViewPage = React.lazy(
  () => import("./pages/result/ResultViewPage")
);
const FaceExtractPage = React.lazy(() => import("./pages/faceapi/Face"));

function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuthorized = useRecoilValue(isAuthorizedState);
  const location = useLocation();

  if (!isAuthorized) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  const [isAuthInitiated] = useAuthStore();

  const initAuth = useInitAuth();

  useEffect(() => {
    const init = async () => {
      await initAuth();
    };
    init().catch();
  }, [initAuth]);

  return !isAuthInitiated ? (
    <Loading />
  ) : (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/password/reset" element={<PasswordResetPage />} />
          <Route path="/password/change" element={<PasswordChangePage />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <TopPage />
              </RequireAuth>
            }
          />
          <Route
            path="/register/"
            element={
              <RequireAuth>
                <FaceExtractPage />
              </RequireAuth>
            }
          />
          <Route
            path="/result/:id"
            element={
              <RequireAuth>
                <ResultViewPage />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
export default App;
